$(function() {
	$('a.recover').on('click', function(e) {
		e.preventDefault();
		$('.popup').fadeIn('fast');

	});
	$('.close-popup').on('click', function(e) {
		e.preventDefault();
		$('.popup').fadeOut('fast');
	});
});